import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';

function App() {
  const words = ["angle", "banlieue", "croyance", "planche", "garantie", "encourager", "enfoncer", "sifflement", "remerciement", "ralentir",
      "ambassadeur", "combattre", "semblable", "sympathique", "importation", "température", "distrait", "absent", "principal", "sourd",
      "adulte", "brave", "journaliste", "scientifique", "touriste", "comique", "sévère", "scolaire", "fragile", "musicien", "technicien",
      "acheteur", "jumeau", "conducteur", "courageux", "menteur", "sportif", "silencieux", "traducteur", "danseur", "neveu", "signal",
      "bijou", "chou", "vitrail", "récital", "extrêmement", "droit", "envers", "convaincre", "indication", "inquiéter", "intervalle",
      "orphelin", "accuser", "confusion", "liaison", "rasoir", "supposition", "utiliser", "zone", "clôture", "croyance", "laboratoire",
      "projectile", "carreau", "seau", "chiffon", "futur", "foudre", "éléphant", "appuyer", "conserver", "murmurer", "durée", "antiquité",
      "facilité", "minorité", "solidarité", "cracher", "carré", "cavalier", "balcon", "courbe", "cuisse", "sculpture", "dictionnaire",
      "fabriquer", "selle", "soustraction", "consigne", "estime", "illustration", "moustique", "veste", "tourisme", "boisson", "ressource",
      "abondance", "domicile", "pouce", "centaine", "concert", "français", "cerf", "déplacer"]
  
  const [index, setIndex] = useState(0);
  const [word, setWord] = useState([]);
  const [count, setCount] = useState("1");
  const handleClick = () => {
    // setWord([]);
    setWord(... words[Math.floor(Math.random() * words.length)])
    console.log(word)
    // word.push(words[Math.floor(Math.random() * words.length)])
    // setWord()
  }
  const handleHardClick = () => {
    if(count === "1"){
      fetch(`https://trouve-mot.fr/api/random`)
            .then(response => response.json())
            .then(data => setWord(data))
            .catch(error => console.error('Error fetching recipes:', error));
    }
    else {
      fetch(`https://trouve-mot.fr/api/random/10`)
            .then(response => response.json())
            .then(data => setWord(data))
            .catch(error => console.error('Error fetching recipes:', error));
    }
  }

  useEffect(() => {
    fetch(`https://trouve-mot.fr/api/random`)
         .then(response => response.json())
         .then(data => setWord(data))
         .catch(error => console.error('Error fetching recipes:', error));
    }, []);

  return (
    <div style={{justifyContent: "center"}}>
      <div className='word'>
        {word.map(w => (
                      <p>{w.name} </p>
                    ))}
        {/* <p>{word} </p> */}
      </div>
      <div className='randomizer-container'>
        {/* <div className='randomizer' onClick={handleClick}>
            <p>Liste</p>
        </div> */}
        <div className='randomizer' onClick={handleHardClick}>
            <p>Hazard</p>
        </div>
        <div className='dropdown-count'>
          <select
            id="sortSelect"
            value={count}
            onChange={(e) => setCount(e.target.value)}
            >
            <option value="1">1</option>
            <option value="10">10</option>
        </select>
        </div>
      </div>
      <div class="footer-bar">
        <div>
          <div class="footer-cat"></div>  
        </div>
    </div>
    </div>
  );
}

export default App;
